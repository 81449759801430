
import { defineComponent, onMounted, ref } from "vue";
import getCssVar from 'quasar/src/utils/get-css-var.js';;
import Avaliacao from "@/interfaces/Avaliacao";
import BaseService from "@/services/admin/BaseService";

export default defineComponent({
  name: "Apexline",
  setup() {
    const loading = ref<boolean>(false);
    const avaliacoes = ref<any[]>();
    const simulado = ref<any[]>();
    const tipoDisponivel = ref<(string | undefined)[] | undefined>([]);
    const chart = ref<any>();
    const chartColumn = ref<any>();
    const getAvaliacoes = (): void => {
      loading.value = true;
      BaseService.list("aluno/minhas-avaliacoes")
        .then((res) => {
          avaliacoes.value = res.data;
          if (avaliacoes.value && "SimulÁureo" in avaliacoes.value) {
            mountChart((avaliacoes.value as { SimulÁureo: any }).SimulÁureo);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const mountChart = (values: any): void => {
      const chaves = Object.keys(values);
      const primeiraChave = chaves[0];
      const categories = values[primeiraChave].map(
        (value: any) => value.assunto
      );
      const data = values[primeiraChave].map((value: any) => value.nota?.valor);

      const dataPortugues = values[primeiraChave].map(
        (value: any) => JSON.parse(value.nota.parametros)[0]?.valor
      );
      const dataMath = values[primeiraChave].map(
        (value: any) => JSON.parse(value.nota.parametros)[1]?.valor
      );
      simulado.value = values[primeiraChave];
      chart.value = {
        options: {
          title: {
            text: "Colocação no Simulado",
            align: "center",
          },
          chart: {
            id: "colocacao-simulado",
            toolbar: {
              show: false,
            },
          },
          colors: [getCssVar("primary"), getCssVar("primary")],
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            categories: categories,
          },
          toolbar: {
            show: false,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
        },
        series: [
          {
            name: "Nota",
            data: data,
          },
        ],
      };

      chartColumn.value = {
        options: {
          title: {
            text: "Colocação no Simulado",
            align: "center",
          },
          chart: {
            id: "colocacao-simulado",
            toolbar: {
              show: false,
            },
          },
          colors: ["#fec350", getCssVar("primary")],
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            categories: categories,
          },
          toolbar: {
            show: false,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
        },
        series: [
          {
            name: "Português",
            data: dataPortugues,
          },
          {
            name: "Matemática",
            data: dataMath,
          },
        ],
      };
    };

    onMounted(() => {
      getAvaliacoes();
    });

    const getMedal = (avaliacao: any) => {
      return avaliacao?.nota?.valor &&
        avaliacao?.nota?.valor >= avaliacao?.nota_minima
        ? require("../../../assets/medalha-aureo.png")
        : require("../../../assets/medalha-cinza.png");
    };
    return {
      avaliacoes,
      tab: ref("table"),
      chart,
      simulado,
      chartColumn,
      getMedal,
    };
  },
  data() {
    return {};
  },
});
